import React, { useContext, useEffect, useState, useRef } from 'react'
import Layout from '../../components/Layout'
import { AuthenticationContext } from '../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import SingleEventFeedCard from '../schoolComponents/SingleEventFeedCard';
import { Link } from 'react-router-dom';
import SeparatorLine from '../schoolComponents/SeparatorLine';
import { PlusIcon,SpeakerXMarkIcon,SpeakerWaveIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import SkeletonEventFeedCard from '../../pageComponents/SkeletonEventFeedCard';

export default function SchoolAllEvents() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [isLoading, setIsLoading] = useState(true);

  const [dateTabs, setDateTabs] = useState([
    { id:"allEvents", name:"All Upcoming",selected:true,count:0},
    { id:"todayEvents", name:"Today",selected:false,count:0},
    { id:"tomorrowEvents", name:"Tomorrow",selected:false,count:0},
    { id:"thisWeekEvents", name:"One Week",selected:false,count:0},
    { id:"thisMonthEvents", name:"One Month",selected:false,count:0},
    { id:"pastEvents", name:"Past",selected:false,count:0} 
  ]);

  const [eventCategories, setEventCategories] = useState([]);

  const [eventList, setEventList] = useState([]);
  
  const [allSchoolEvents, setAllSchoolEvents] = useState([]);

  const [todayEvents, setTodayEvents] = useState([]);
  const [tomorrowEvents, setTomorrowEvents] = useState([]);
  const [thisWeekEvents, setThisWeekEvents] = useState([]);
  const [thisMonthEvents, setThisMonthEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]); // State for past events

  //  ------------------ Added for Intersection Observer ------------------
  const scrollableContainerRef = useRef(); // Added ref for scrollable container
  //  ---------------------------------------------------------------

  const handleSelectDateTab = (id, updateEvents = true) => { 
    // Added updateEvents parameter
    let copyDateTabs = [...dateTabs];
    copyDateTabs.forEach((dateTab)=>{
      if(dateTab.id === id){
        dateTab.selected = true;
        if (updateEvents) { // Added condition to check updateEvents
          if(id === "todayEvents"){
            setEventList(todayEvents);
            dateTab.count = todayEvents.length;
            sortCategories(todayEvents)
          }else if(id === "tomorrowEvents"){
            setEventList(tomorrowEvents);
            dateTab.count = tomorrowEvents.length
            sortCategories(tomorrowEvents)
          } else if(id === "thisWeekEvents"){
            setEventList(thisWeekEvents);
            dateTab.count = thisWeekEvents.length
            sortCategories(thisWeekEvents)
          } else if(id === "thisMonthEvents"){
            setEventList(thisMonthEvents);
            dateTab.count = thisMonthEvents.length
            sortCategories(thisMonthEvents)
          } else if (id === "allEvents"){
            setEventList(allSchoolEvents);
            dateTab.count = allSchoolEvents.length
            sortCategories(allSchoolEvents)
          } 
        }
      }else{
        dateTab.selected = false;
      }
    })
    setDateTabs(copyDateTabs);
    eventCategories.forEach((category)=>{
      category.selected = false;
      }
    )
  }

  const handleDateTableCount = (allEvents) => {
    let copyDateTabs = JSON.parse(JSON.stringify(dateTabs));
    let today = moment().startOf('day');
    let tomorrow = moment().add(1, 'day').startOf('day');
    let aWeekFromNow = moment().add(7, 'days').endOf('day');
    let aMonthFromNow = moment().add(1, 'month').endOf('day');
  
    // Reset counts
    copyDateTabs.forEach(tab => tab.count = 0);
  
    allEvents.forEach((event) => {
      let eventDateTime = moment(event.eventDateTime).startOf('day');
      
      if (eventDateTime.isSameOrAfter(today)) {
        copyDateTabs.find(tab => tab.id === 'allEvents').count += 1;
      }
      if (eventDateTime.isSame(today, 'day')) {
        copyDateTabs.find(tab => tab.id === 'todayEvents').count += 1;
      }
      if (eventDateTime.isSame(tomorrow, 'day')) {
        copyDateTabs.find(tab => tab.id === 'tomorrowEvents').count += 1;
      }
      if (eventDateTime.isBetween(today, aWeekFromNow, 'day', '[]')) {
        copyDateTabs.find(tab => tab.id === 'thisWeekEvents').count += 1;
      }
      if (eventDateTime.isBetween(today, aMonthFromNow, 'day', '[]')) {
        copyDateTabs.find(tab => tab.id === 'thisMonthEvents').count += 1;
      }
      if (eventDateTime.isBefore(today)) {
        copyDateTabs.find(tab => tab.id === 'pastEvents').count += 1;
      }
    });
    setDateTabs(copyDateTabs);
  };

  // Added a new function to handle past events tab
  const handlePastEventsTab = () => {
    let copyDateTabs = [...dateTabs];
    copyDateTabs.forEach((dateTab) => {
      dateTab.selected = dateTab.id === "pastEvents";
    });
    setDateTabs(copyDateTabs);
    setEventList(pastEvents); // Set event list to past events
    sortCategories(pastEvents); // Sort categories based on past events
  };

  const handleEventLists = (events) => {
    let today = moment().startOf('day');
    let tomorrow = moment(today).add(1,'day').startOf('day');
    let thisWeek = moment(today).add(7,'day');
    let thisMonth = moment(today).add(1,'month');

    // Sort events by date
    events.sort((a, b) => new Date(a.eventDateTime) - new Date(b.eventDateTime));

    let todayEvents = [];
    let tomorrowEvents = [];
    let thisWeekEvents = [];
    let thisMonthEvents = [];
    let pastEvents = [];
    let allUpcomingEvents = [];

    events.forEach((event) => {
        let eventDate = moment(event.eventDateTime).startOf('day');
        if (eventDate.isBefore(today)) {
            pastEvents.push(event); // Collect past events
        } else if (eventDate.isSame(today,'day')) {
            todayEvents.push(event);
        } else if (eventDate.isSame(tomorrow,'day')) {
            tomorrowEvents.push(event);
        } else if (eventDate.isBetween(today,thisWeek,'day','[]')) {
            thisWeekEvents.push(event);
        } else if (eventDate.isBetween(today,thisMonth,'day','[]')) {
            thisMonthEvents.push(event);
        }
    });

    // Include today and tomorrow events in thisWeekEvents
    thisWeekEvents = [...new Set([...todayEvents, ...tomorrowEvents, ...thisWeekEvents])];

    // Include today, tomorrow, and this week events in thisMonthEvents
    thisMonthEvents = [...new Set([...todayEvents, ...tomorrowEvents, ...thisWeekEvents, ...thisMonthEvents])];

    setTodayEvents(todayEvents);
    setTomorrowEvents(tomorrowEvents);
    setThisWeekEvents(thisWeekEvents);
    setThisMonthEvents(thisMonthEvents);
    setPastEvents(pastEvents); // Set past events state
  };

  const sortCategories = (events)=>{
    let categories = [];
    events.forEach((event)=>{
      if(event.eventCategory_id){
        categories.push({categoryName:event.eventCategory?.categoryName, id:event.eventCategory_id, selected:false});
      }
    })
    const uniqueCategories = [];
    const categoryIds = new Set();
  
    categories.forEach((category) => {
      if (!categoryIds.has(category.id)) {
        uniqueCategories.push(category);
        categoryIds.add(category.id);
      }
    });
  
    categories = uniqueCategories;
    setEventCategories(categories);
  }

  const getEvents = async()=>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          let upcomingEvents = response.data.payload.filter(event =>  moment(event.eventDateTime).isAfter(moment()));
          
          setAllSchoolEvents(upcomingEvents);

          handleEventLists(response.data.payload);
          
          setEventList(upcomingEvents);

          handleDateTableCount(response.data.payload);

          // handle categories to be all upcoming
          let categories = [];
          upcomingEvents.forEach((event)=>{
            if(event.eventCategory_id){
              categories.push({categoryName:event.eventCategory?.categoryName, id:event.eventCategory_id, selected:false});
            }
          })
          categories = removeDuplicateCategories(categories);
          setEventCategories(categories);
        }
      })
      .catch((err)=>console.log(err)
      ).finally(()=>{
        setIsLoading(false);
      })
  }

  const handleSelectCategory = (id)=>{
    let copyCategories = [...eventCategories];
    let selectedDateTab = dateTabs.find((dateTab)=>dateTab.selected === true);
    let filteredEvents = [];
    if(selectedDateTab.id === "todayEvents"){
      filteredEvents = todayEvents;
    }else if(selectedDateTab.id === "tomorrowEvents"){
      filteredEvents = tomorrowEvents;
    } else if(selectedDateTab.id === "thisWeekEvents"){
      filteredEvents = thisWeekEvents;
    } else if(selectedDateTab.id === "thisMonthEvents"){
      filteredEvents = thisMonthEvents;
    } else if(selectedDateTab.id === "allEvents"){
      filteredEvents = allSchoolEvents;
    } else if(selectedDateTab.id === "pastEvents"){
      filteredEvents = pastEvents
    }
    
  // Filter events based on the selected category
    copyCategories.forEach((category)=>{
      if(category.id === id){
        category.selected = true;
        setEventList(filteredEvents.filter((event)=>{
          return event.eventCategory_id === id
        }));
      }else{
        category.selected = false;
      }
    })
    
    copyCategories = removeDuplicateCategories(copyCategories);
    setEventCategories(copyCategories);
  }

  useEffect(()=>{
    getEvents();
  },[])
  useEffect(()=>{
    document.title = "Schoolley | All Events"
},[]);

  return (
<Layout>
  <div className="md:w-full w-full flex justify-center">
    <div className="bg-opacity-75 px-1 pb-2 flex justify-center w-full">
      <div id="event-page-layout" className="lg:grid grid-cols-10 gap-4 w-full">
        {/* --------- Event Timeline --------- */}
        <div className="col-span-3 lg:col-span-2 h-fit ">
          <div id="event-timeline" className="pb-2 text-sm font-semibold text-gray-600 sticky top-10 px-2 lg:px-5">
            <p className="text-center text-indigo-500 py-0 ">TIME</p>
            <SeparatorLine margin={2} />
            <div className="grid grid-cols-3 lg:grid-cols-1">
              {
                isLoading?
                <div className="animate-pulse col-span-3 grid grid-cols-3 lg:grid-cols-1 lg:space-y-1">
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                  <div className="w-full h-8 bg-white shadow-sm lg:rounded-xl"></div>
                </div>:
                  dateTabs.map((dateTab, index) => {
                  if (dateTab.id !== "pastEvents") { // Exclude past events button from here
                    return (
                      <button
                        key={index}
                        onClick={() => handleSelectDateTab(dateTab.id)}
                        className={`transition delay-50 grid grid-cols-4 justify-center w-full px-2 py-1 lg:py-2 my-1 text-center lg:rounded-lg ${dateTab.selected ? 'bg-blue-400 text-white shadow-sm' : 'hover:bg-blue-500 hover:text-white'}`}>
                        <div className='w-full col-span-3'>
                          <p className={`${dateTab.selected ? 'uppercase' : ''}`}>
                          {dateTab.name} 
                          </p>
                        </div>
                        <div className='flex justify-end '>
                          <p className={`ml-2 rounded-full text-blue-700 inline-flex items-center justify-center w-5 h-5 ${dateTab.count>0? "bg-white  ":"bg-slate-300"}`}>{dateTab.count}</p>
                        </div> 
                      </button>
                    );
                  }
                  return null;
                })
              }
              {
                isLoading? null:
                <button
                  onClick={handlePastEventsTab} 
                  className={`transition delay-50 grid grid-cols-4 justify-center px-2 py-1 lg:py-2 my-1 w-full text-center lg:rounded-lg ${dateTabs.find(tab => tab.id === "pastEvents").selected ? 'bg-slate-400 text-white shadow-sm ' : 'hover:bg-slate-300'}`}>
                  <div className='w-full col-span-3'>
                    <p> Past</p>
                  </div>
                  <div className='flex justify-end '>
                    <p className={`ml-2 rounded-full text-slate-700 inline-flex items-center justify-center w-5 h-5 ${dateTabs.find(tab => tab.id === "pastEvents").count>0? "bg-white  ":"bg-slate-300"}`}>{dateTabs.find(tab => tab.id === "pastEvents").count}</p>
                  </div> 
                </button>
              }
            </div>
          </div>
        </div>

        {/* --------- event content --------- */}
        <div id="event-content" className="col-span-7 lg:col-span-7 lg:px-2 pb-2 lg:mx-5 relative ">
          <div className="">
            <div className="flex justify-between ">
              <p className="font-semibold top-20 my-0 text-sky-600 text-2xl">School Events</p>
              <Link to="/school/events/new">
                <button className="bg-sky-500 font-semibold shadow-sm px-3 py-1 text-white mx-0 hover:bg-sky-600 rounded-lg flex items-center justify-center">
                <PlusIcon className="h-5 w-5 inline-block mr-2" />
                New Event</button>
              </Link>
            </div>
            <div className="grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-1 px-3">
              {
                isLoading? 
              <div className="animate-pulse my-1">
                <button className='px-4 py-2.5 mx-1 my-0 shadow-sm border-2 border-indigo-500 text-sm font-semibold text-slate-800 text-xs rounded-2xl hover:bg-indigo-600 hover:text-white '>
                </button>
                <button className='px-4 py-2.5 mx-1 my-0 shadow-sm border-2 border-indigo-500 text-sm font-semibold text-slate-800 text-xs rounded-2xl hover:bg-indigo-600 hover:text-white '>
                </button>
                <button className='px-4 py-2.5 mx-1 my-0 shadow-sm border-2 border-indigo-500 text-sm font-semibold text-slate-800 text-xs rounded-2xl hover:bg-indigo-600 hover:text-white '>
                </button>
              </div>
              :
                eventCategories.map((category, index) => {
                return (
                  <button
                    key={category.id}
                    onClick={() => handleSelectCategory(category.id)}
                    className={`px-3 py-1 mx-1 my-1 border-2 border-indigo-500 text-sm font-semibold text-slate-800 text-xs rounded-2xl hover:bg-indigo-600 hover:text-white ${category.selected ? 'bg-indigo-500 text-white shadow' : 'shadow-sm'}`}>
                    {category.categoryName?.toUpperCase()}
                  </button>
                );
              })
              }
            </div>
          </div>

          <div className="overflow-y-auto max-h-[800px] px-3" ref={scrollableContainerRef}>
            {
              isLoading?
              <div className="animate-pulse">
                <SkeletonEventFeedCard />
                <SkeletonEventFeedCard />
                <SkeletonEventFeedCard />
                <SkeletonEventFeedCard />
              </div>
              :eventList.length === 0? 
                <p className="text-center text-gray-500 my-10 text-lg">No event available</p>
                :eventList.map((event, index) => {
                return (
                  <div 
                    key={index}
                    className='mb-4'
                    >
                    <SingleEventFeedCard eventObject={event} />
                  </div>
                  
                );
              })
            }
          </div>
        </div>
        {/* --------- Announcements --------- */}

        {/* <div id="pinned-announcement" className="col-span-3 lg:px-0 lg:sticky top-20 ">
          <div className="pb-1 relative">
            <p className="text-center font-semibold py-2 px-1 sticky top-0 z-10 ">
              <SpeakerWaveIcon className="h-5 w-5 inline-block mr-2" />
              Today's Announcements
            </p>
            <SeparatorLine margin={2} />
            <div id="announcement-content" className="space-y-3 overflow-y-scroll px-2">
              <p className='my-5 text-3xl text-slate-500 text-center'>Coming Soon</p>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  </div>
</Layout>
  )
}

const removeDuplicateCategories = (categories) => {
  const uniqueCategories = [];
  const categoryIds = new Set();

  categories.forEach((category) => {
    if (!categoryIds.has(category.id)) {
      uniqueCategories.push(category);
      categoryIds.add(category.id);
    }
  });

  return uniqueCategories;
};

