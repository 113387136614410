import React, { useEffect } from 'react';
import Layout from './components/Layout';
import UnAuthLayout from './components/UnAuthLayout';


export default function PrivacyPolicies() {
  
  useEffect(() => {
    document.title = "Schoolley | Privacy Policy";
  }, []);
  
  return (
    <UnAuthLayout>
      <div className="md:w-full w-full">
        <div className='bg-opacity-75 rounded-2xl bg-white p-3'>
          <h2 className='font-extrabold text-2xl'>Privacy Policy</h2>
          <p>Effective Date: [Date]</p>

          <h3 className='font-bold text-xl mt-4'>1. Introduction</h3>
          <p>Welcome to Schoolley. Schoolley.com is committed to protecting the privacy and security of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>

          <h3 className='font-bold text-xl mt-4'>2. Information We Collect</h3>
          <p>We may collect and process the following types of information:</p>
          <ul className='list-disc pl-5'>
            <li><strong>Personal Information:</strong> When you register on Schoolley, we may collect personal details such as your name, email address, and any other details necessary to create your account.</li>
            <li><strong>Usage Data:</strong> We collect information about your interactions with our site, including the pages you visit, the time and date of your visits, and the links you click.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor activity on our website and store certain information.</li>
          </ul>

          <h3 className='font-bold text-xl mt-4'>3. How We Use Your Information</h3>
          <p>The information we collect from you is used in the following ways:</p>
          <ul className='list-disc pl-5'>
            <li>To provide and maintain our services.</li>
            <li>To improve and personalize your experience on Schoolley.</li>
            <li>To communicate with you, including sending you important notices and updates.</li>
            <li>To analyze usage patterns and improve our website.</li>
            <li>To comply with legal obligations and protect our rights.</li>
          </ul>

          <h3 className='font-bold text-xl mt-4'>4. Sharing Your Information</h3>
          <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except:</p>
          <ul className='list-disc pl-5'>
            <li><strong>Service Providers:</strong> We may share your information with third-party vendors who assist us in operating our website or providing our services, as long as these parties agree to keep your information confidential.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
          </ul>

          <h3 className='font-bold text-xl mt-4'>5. Data Security</h3>
          <p>We implement a variety of security measures to maintain the safety of your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

          <h3 className='font-bold text-xl mt-4'>6. Your Rights and Choices</h3>
          <p>You have the following rights concerning your personal information:</p>
          <ul className='list-disc pl-5'>
            <li><strong>Access and Correction:</strong> You can access and update your personal information by logging into your account.</li>
            <li><strong>Deletion:</strong> You may request the deletion of your personal information. However, we may need to retain certain information for legal or administrative purposes.</li>
            <li><strong>Opt-Out:</strong> You can opt out of receiving promotional emails from us by following the unsubscribe instructions provided in those emails.</li>
          </ul>

          <h3 className='font-bold text-xl mt-4'>7. Third-Party Links</h3>
          <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to read their privacy policies.</p>

          <h3 className='font-bold text-xl mt-4'>8. Changes to This Privacy Policy</h3>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" above. Your continued use of our website after any changes constitutes your acceptance of the new terms.</p>

          <h3 className='font-bold text-xl mt-4'>9. Contact Us</h3>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>Email: [Your Contact Email]</p>
          <p>Address: [Your Physical Address]</p>
          <p>Phone: [Your Contact Phone Number]</p>
        </div>
      </div>
    </UnAuthLayout>
  );
}
