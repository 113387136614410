import React from 'react'
import { Link, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { useEffect, useState,useContext } from 'react';
import moment from 'moment';
// import { } from '@heroicons/react/24/solid';
import { HandRaisedIcon, ClockIcon, MapPinIcon, LockOpenIcon, LockClosedIcon,ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import SeparatorLine from '../schoolComponents/SeparatorLine';
import SchoolDeleteEventButton from '../schoolComponents/SchoolDeleteEventButton';
import Linkify from 'linkify-react';
import SkeletonLoader from '../../pageComponents/SkeletonLoader';
import ReminderConfirm from '../schoolComponents/ReminderConfirm';
import { useCornerWarning } from '../../../services/SystemCornerWarning';
export default function SchoolAllEventDetails() {

    const {eventId} = useParams();
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const [isLoading, setIsLoading] = useState(true);

    const [eventDetails, setEventDetails] = useState({});
    const [gradeLevels, setGradeLevels] = useState([]);

    const linkifyOptions = {
      className: 'text-blue-500 underline', // Tailwind classes for styling the links
      target: '_blank', // Open links in a new tab
      rel: 'noopener noreferrer', // For security reasons
    };

  const {showWarning} = useCornerWarning();


    const [inviteesTable, setInviteesTable] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [accepted, setAccepted] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [pending, setPending] = useState([]);

    const [statusTabs, setStatusTabs] = useState([
      {active:true, tabName:"INVITED",content:invitees,bgColor:"bg-green-400"},
      {active:false, tabName:"ACCEPTED",content:accepted,bgColor:"bg-green-500"},
      {active:false, tabName:"DECLINED",content:declined,bgColor:"bg-red-500"},
      {active:false, tabName:"PENDING",content:pending,bgColor:"bg-amber-500"}
    ]);

    const handleSetContent = (index, statusContent)=>{
      const newActiveTabs = [...statusTabs];
      newActiveTabs[index].content = statusContent;
      setStatusTabs(newActiveTabs);
    }

    const handleInviteeTable = (statusData, index)=>{
      setInviteesTable(statusData);
      const newActiveTabs = [...statusTabs];
      newActiveTabs.forEach((tab)=>{
        tab.active = false;
      });
  
      newActiveTabs[index].active = true;
      setStatusTabs(newActiveTabs);
    }

    const handleAllInvitees = (statusData)=>{
      setInvitees(statusData);
      handleSetContent(0, statusData);
    }

    const handleAccepted = (statusData)=>{
      const accepted = statusData.filter((invitee)=>invitee.status==="accepted");
      setAccepted(accepted);
      handleSetContent(1, accepted);
      
    }

    const handleDeclined = (statusData)=>{
      const declined = statusData.filter((invitee)=>invitee.status==="declined");
      setDeclined(declined);
      handleSetContent(2, declined);
    }

    const handlePending = (statusData)=>{
      const pending = statusData.filter((invitee)=>invitee.status==="pending");
      setPending(pending);
      handleSetContent(3, pending);
    }

    const loadEventDetails = async()=>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events/${eventId}`,{withCredentials:true})
      .then((response)=>{
          let eventDetails = response.data.payload;
          setEventDetails(eventDetails)
          let invitedStudents = eventDetails.invitations.map((invitation)=>{
            return invitation.student;
          });
          handleAllInvitees(eventDetails.invitations);
          
          handleAccepted(eventDetails.invitations); 
          handleDeclined(eventDetails.invitations);
          handlePending(eventDetails.invitations);
          handleInviteeTable(eventDetails.invitations, 0);
          getGradeLevels(eventDetails);

      }).catch((error)=>{
          console.log("error in loading event details",error)
      }).finally(()=>{
          setIsLoading(false);
      })
    }

    useEffect(()=>{
        loadEventDetails()
    },[])

  const [showCopyMessage, setShowCopyMessage] = useState(false);
  
  const copyTableToClipBoard = ()=>{
    const table = document.getElementById('inviteesTable');
    const rows = Array.from(table.querySelectorAll('tr'));
    
    const text = rows.map(row => {
      // Get all td elements (since th elements in the header won't be considered)
      const cells = Array.from(row.querySelectorAll('td'));
      
      // Only process rows that have cells (to skip header row)
      if (cells.length > 0) {
        // Extract 'Grade' and 'Name' from the correct columns
        const name = cells[0].textContent; // Name is the first column
        const grade = cells[1].textContent; // Grade is the second column
  
        // Return Grade before Name with a tab delimiter
        return `G${grade}\t${name}`;
      }
      return ''; // Skip rows with no content
    }).filter(row => row !== '').join('\n'); 
    // Filter out empty rows and join all text
  
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 1000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      console.warn('Clipboard API not supported');
    }
  }

  const [emailCopied, setEmailCopied] = useState(false);

  const handleCopyEmails = ()=>{
    const emails = inviteesTable.map((invitee)=>{
      return invitee.student.email;
    }).join(", ");
    if (navigator.clipboard) {
      navigator.clipboard.writeText(emails).then(() => {
        setEmailCopied(true);
        setTimeout(() => setEmailCopied(false), 3000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      console.warn('Clipboard API not supported');
    }
  }

  const getGradeLevels = (eventObject)=>{
    let students = eventObject.invitations.map((invitation)=>{
        return invitation.student
        }
    )
    let gradeLevels = students.map((student)=>{
        return student.gradeLevel
    })
    gradeLevels = gradeLevels.sort((a,b)=>a-b);
    gradeLevels = [...new Set(gradeLevels)];
    setGradeLevels(gradeLevels);
  }

  useEffect(()=>{
    document.title ="Event Details";
  },[])

  const handleRemindPending = async()=>{
    let eventDateTime = moment(eventDetails?.eventDateTime);

    if(moment().isAfter(eventDateTime)){
      showWarning("Event has passed", "text-orange-500");
      return
    }
    if(pending.length === 0){
      showWarning("No pending attendees to remind", "text-orange-500");
      return
    }
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/events/${eventId}/remind-pending`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        showWarning("Reminder emails has been sent", "text-green-500");
      }
    }).catch((error)=>{
      // showBanner("Error sending reminders", "bg-red-500");
      showWarning("Reminder error", "text-orange-500");
    })
  }
  
  const handleRemindAll = async()=>{
    let eventDateTime = moment(eventDetails?.eventDateTime);

    if(moment().isAfter(eventDateTime)){
      showWarning("Event has passed", "text-orange-500");
      return
    }
    if(invitees.length === 0){
      showWarning("No attendees to remind", "text-orange-500");
      return
    }
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events/${eventId}/remind-all`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        showWarning("Reminder emails has been sent", "text-green-500");
      }
    }).catch((error)=>{
      // showBanner("Error sending reminders", "bg-red-500");
      showWarning("Reminder error", "text-orange-500");
    })
  }

  const handleRemindAccepted = async()=>{
    let eventDateTime = moment(eventDetails?.eventDateTime);

    if(moment().isAfter(eventDateTime)){
      showWarning("Event has passed", "text-orange-500");
      return
    }
    if(accepted.length === 0){
      showWarning("No accepted attendees to remind", "text-orange-500");
      return
    }
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/events/${eventId}/remind-accepted`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        showWarning("Reminder emails has been sent", "text-green-500");
      }
    })
    .catch((error)=>{
      // showBanner("Error sending reminders", "bg-red-500");
      console.log(error)
      showWarning("Reminder error", "text-orange-500");
    })
  }

  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 lg:grid grid-cols-10 gap-x-2 lg:mx-10'>
          <div className='col-span-6 lg:min-h-fit rounded-tl-2xl lg:mr-3'>
            <div id='event-info-section' className='mb-2 bg-white rounded-tl-2xl shadow-sm'>
              <div className='font-semibold px-3 py-2 bg-indigo-500 lg:rounded-tl-2xl flex justify-between'>
                <div className='text-lg text-white'>{
                  isLoading?
                  <SkeletonLoader width="44" height="7" style={"bg-indigo-100 rounded-2xl"} className="rounded-full"/> 
                  :eventDetails.eventName}
                </div>
                <p className='font-semibold text-sm rounded w-fit px-4 bg-white flex items-center justify-center'>{
                  eventDetails.eventCategory?.categoryName.toUpperCase()}
                </p>
              </div>
              <div className='px-3 py-2'>     
                <div className='grid grid-cols-3 items-center text-sm'>
                  <div className='flex items-center '>
                    <ClockIcon className='h-7 w-7 text-sky-500'/>
                    <p className='font-semibold mx-1 '>Time: </p>
                  </div>
                  <div className='flex justify-between lg:px-5 items-center col-span-2 text-sm'>
                    <div className='font-semibold tracking-wide my-2 text-center rounded-2xl text-sky-600 px-3 py-1 bg-green-600 text-white'>{
                      isLoading?
                      <SkeletonLoader width="16" height="5" style={"bg-green-100 rounded-2xl"} />
                      :moment(eventDetails?.eventDateTime).calendar(null,momentShowTime)
                      }
                    </div>
                    <div className='font-semibold tracking-wide my-2 w-fit rounded-lg text-green-600 px-2 '>{
                      isLoading?
                      <SkeletonLoader width="44" height="6" style={"bg-green-100 "} />
                      :moment(eventDetails?.eventDateTime).calendar(null,momentShowDate)
                      } 
                    </div>
                  </div>
                </div>
                
                <SeparatorLine margin={1} />
                <div className='grid grid-cols-3 items-center text-sm'>
                  <div className='flex items-center '>
                    <ClockIcon className='h-7 w-7 text-amber-400 font-semibold' />
                    <p className='font-semibold mx-1 '>End at: </p>
                  </div>

                  <div className='flex justify-between lg:px-5 items-center col-span-2 text-sm'>
                    <div className='font-semibold tracking-wide my-2 text-center rounded-2xl px-3 py-1 bg-amber-400 text-slate-800'>{
                      isLoading?
                      <SkeletonLoader width="16" height="5" style={"bg-amber-100 rounded-2xl"} />
                      :moment(eventDetails?.eventEndTime).calendar(null,momentShowTime)
                      }
                    </div>
                    <div className='font-semibold tracking-wide my-2 w-fit rounded-lg px-2 '>{
                      isLoading?
                      <SkeletonLoader width="44" height="6" style={"bg-amber-100 "} />
                      :moment(eventDetails?.eventEndTime).calendar(null,momentShowDate)}
                    </div>
                  </div>
                </div>
                <SeparatorLine margin={1} />
                  <div className='grid grid-cols-3 items-center text-sm'>
                    <div className='flex items-center '>
                      <HandRaisedIcon className='h-7 w-7 text-red-500' />
                      <p className='font-semibold mx-1 '>Sign up Deadline: </p>
                    </div>
                    <div className='flex justify-between lg:px-5 items-center text-sm col-span-2 '>
                      <div className='font-semibold tracking-wide my-2 text-center rounded-2xl px-3 py-1 bg-red-500 text-white '> {
                        isLoading?
                        <SkeletonLoader width="16" height="5" style={"bg-red-100 rounded-2xl"} />
                        :moment(eventDetails?.signUpDeadline).calendar(null,momentShowTime)}
                      </div>
                      <div className='font-semibold tracking-wide my-2 w-fit rounded-lg px-2 text-red-500 '> {
                        isLoading?
                        <SkeletonLoader width="44" height="6" style={"bg-red-100 "} />
                        :moment(eventDetails?.signUpDeadline).calendar(null,momentShowDate)}
                      </div>
                    </div>
                  </div>
                <SeparatorLine margin={1} />
                  <div className='grid grid-cols-3 items-center text-sm justify-between'>
                    <div className='flex items-center '>
                      <MapPinIcon className='h-7 w-7 text-blue-500 ' />
                      <p className='font-semibold mx-1 '>Location: </p>
                    </div>
                    <div className='flex justify-between lg:px-5 items-center col-span-2 text-sm'>
                      <div className='font-semibold my-2 text-center border-2 border-blue-500 rounded-2xl text-blue-500 px-3'>{
                        isLoading?
                        <SkeletonLoader width="16" height="5" style={"bg-slate-100 "} />
                        :eventDetails?.location}
                      </div>
                    </div>
                  </div>

                <SeparatorLine margin={1} />

                <div className='grid grid-cols-3 items-center text-sm'>
                    <div className='flex items-center '>
                  {eventDetails.openToAll?
                      <LockOpenIcon className='h-7 w-7 text-blue-500' /> :
                      <LockClosedIcon className='h-7 w-7 text-red-500' /> 
                  }
                  <p className='font-semibold mx-2 '>Event Type: </p>
                  </div>
                  <div className='flex justify-between lg:px-5 items-center col-span-2  text-sm'>
                    {
                      isLoading?
                        <SkeletonLoader width="28" height="6" style={"font-semibold my-2 text-center rounded-2xl text-white px-3 py-1 bg-green-500"} />
                        :
                        eventDetails.openToAll?
                          <p className='font-semibold my-2 text-center rounded-2xl text-white px-3 py-1 bg-green-500 '>OPEN TO ALL</p>
                          :
                          <p className='font-semibold my-2 w-fit rounded-2xl text-sky-600 px-2 '>INVITE ONLY</p>
                    }
                  </div>
                </div>

                  <SeparatorLine margin={1} />

                  <div className='lg:grid grid-cols-2 items-center text-sm justify-between my-2 '>
                    <p className='font-semibold text-xs'>Organized By: 
                    {eventDetails.staff?.firstName} {eventDetails.staff?.lastName}</p>
                  </div>
                  <SeparatorLine margin={1} />

              </div>
            </div>

            <div className='lg:min-h-fit h-24 shadow-sm bg-white lg:my-0 lg:my-4'>
              <p className='px-3 py-2 font-semibold'>Grades Included</p>
              <div className='px-3 flex space-x-3'>
                {
                  isLoading?
                    <SkeletonLoader width="10" height="10" style={"bg-slate-100 rounded-full "} />
                    :
                    gradeLevels.map((grade,index)=>{
                        return <p key={index} className='font-semibold text-base text-center flex items-center justify-center bg-yellow-300 w-10 h-10 rounded-full text-center shadow-sm border'>{grade}</p>
                    })
                }
              </div>
            </div>

            <div className='lg:min-h-fit shadow-sm bg-white lg:my-0 lg:my-4'>
              <p className='px-3 py-2 bg-sky-700 text-white font-semibold'>Details</p>
              <div className='px-3 py-2 text-sm font-semibold whitespace-pre-wrap'>
                {isLoading?
                  <SkeletonLoader width="full" height="5" style={"bg-slate-100 "} />
                    :
                  <Linkify as='span' options={linkifyOptions}>
                    {eventDetails?.remark}
                  </Linkify>
                }
              </div>
            </div>
            <div className='flex gap-x-2 mt-2 bg-white px-3 py-3 lg:rounded-bl-2xl '>
              {
                isLoading?
                <>
                  <button className='transition delay-100 bg-green-500 text-white font-semibold rounded-lg px-4 py-1.5 hover:bg-green-600 animate-pulse'>Edit Event</button>
                  <button className='transition delay-100 bg-red-500 text-white font-semibold rounded-lg px-4 py-1.5 hover:bg-green-600 animate-pulse'>Delete Event</button>
                </>
                :
                eventDetails.staff?.id === userId?
                moment(eventDetails.eventDateTime).isAfter(moment())?
                <>
                  <Link to={`/school/events/${eventId}/edit`} >
                    <button className='transition delay-100 bg-green-500 text-white font-semibold rounded-lg px-4 py-1.5 hover:bg-green-600'>Edit Event</button>
                  </Link>
                  <SchoolDeleteEventButton event={eventDetails} />
                </>
                 :<SchoolDeleteEventButton event={eventDetails} />
                :<p className='text-sm font-semibold'>Event Created by: {eventDetails.staff?.firstName} {eventDetails.staff?.lastName}</p>
              }
            </div>
          </div>
          
          {/* Attendance column */}
          <div id='attendance-section' className='col-span-4 bg-white lg:rounded-r-2xl relative'>
            <div className='flex justify-between items-center bg-indigo-500 text-white lg:rounded-tr-2xl'>
              <p className='px-3 py-1 font-semibold'>Attendance</p>
              <button className='mx-2 p-1 rounded relative' onClick={copyTableToClipBoard}>
                <ClipboardDocumentIcon className='h-6 w-6 hover:text-slate-300'/>
              </button>
              {showCopyMessage && (
                <div className="absolute top-0 right-0 bg-red-500 font-semibold px-2 py-1 rounded-tr-2xl text-base">
                  Copied!
                </div>
              )}
            </div>
           {/* overall count / status tabs */}
            <div className='py-0 shadow-sm mb-2 grid grid-cols-4 bg-white'>
                {
                  statusTabs.map((status, index)=>{
                    return (
                      <button 
                        key={index} 
                        onClick={()=>handleInviteeTable(status.content, index)} 
                        className={`transition delay-100 text-center py-2 ${status.active?status.bgColor:""}`}>
                        <p className='text-3xl '>{status.content.length}</p>
                        <p className={`text-sm font-semibold text-slate-400 ${status.active?"text-white":""} `}>{status.tabName}</p>
                      </button>
                    )
                  })
                }
            </div>
              {/* invitation table */}
            <div className='py-0 shadow-sm mb-2 bg-white max-h-[560px] overflow-auto relative'>
              <table id='inviteesTable' className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr className='sticky top-0 grid grid-cols-3 text-center'>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0 col-span-1">Name</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0">Grade</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0">Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-300'>
                  {
                    inviteesTable.map((invitation)=>{
                      return (
                        <tr key={invitation.id} className='hover:bg-gray-100 text-center grid grid-cols-3'>
                          <td className='whitespace-nowrap text-sm text-blue-600 font-semibold py-3'>
                            <Link to={`/school/students/${invitation?.student.id}`} >
                              {invitation.student.firstName} {invitation.student.lastName}
                            </Link>
                          </td>
                          <td className='whitespace-nowrap px-3 py-3 text-sm'>{invitation.student.gradeLevel}</td>
                          <td className='whitespace-nowrap px-3 py-3 text-sm'>
                            <p className={`mx-3 rounded font-semibold ${invitation.status ==="accepted"?"bg-green-600 text-white ":invitation.status==="declined"?"bg-red-500 text-white":"border-1 border-amber-500 "}`}>{invitation.status.toUpperCase()}</p>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

            {/* email reminder section */}
            <div className='px-2 bg-white rounded'>
              <div className='py-2 flex justify-between border-b'>
                <p className='font-bold text-sm text-sky-700 '>REMINDER</p>
                <div className="flex justify-start">
                  {
                    emailCopied?
                    <div className='px-2 py-1 '>
                      <p className='font-semibold text-slate-600 text-sm'>Emails copied!</p>
                    </div>
                    :                  
                    <button 
                      onClick={handleCopyEmails}
                      className='px-2 py-1 text-blue-500 font-semibold flex justify-center text-sm hover:text-slate-600'>
                      <ClipboardDocumentIcon className='h-5 w-5'/>
                      Copy Email Addresses ( {inviteesTable.length} )
                    </button>
                  }
                </div>
              </div>
              <div className="flex justify-center">
                <ReminderConfirm buttonName="All" studentCount={invitees.length} sendEmail={handleRemindAll} styling={"rounded-r-sm rounded-l-2xl bg-blue-500 hover:bg-blue-600 "}/>
                <ReminderConfirm buttonName="Attending" studentCount={accepted.length} sendEmail={handleRemindAccepted} styling={"rounded-sm bg-green-500 hover:bg-green-600"}/>
                <ReminderConfirm buttonName="Pending" studentCount={pending.length} sendEmail={handleRemindPending} styling={"rounded-r-2xl rounded-l-sm bg-amber-500 hover:bg-amber-600"}/>
              </div>
            </div>


            {/* email reminder section ends */}

          </div>
          {/* <div className='col-span-1' /> */}
        </div>
      </div>
    </Layout>
  )
}

const momentShowTime = {
  sameDay:  'LT', // Today (Monday) at 2:30 PM
  nextDay:  'LT', // Tomorrow (Tuesday) at 2:30 PM
  nextWeek: 'LT', // Sunday, 09/20/2020 at 2:30 PM
  lastDay:  'LT', // Yesterday (Sunday) at 2:30 PM
  lastWeek: 'LT', // Last Monday, 09/14/2020 at 2:30 PM
  sameElse: 'LT' // 09/14/2020 (Friday) at 2:30 PM
};
const momentShowDate = {
  sameDay: '[Today] (dddd)', // Today (Monday)
  nextDay: '[Tomorrow] (dddd)', // Tomorrow (Tuesday)
  nextWeek: 'MMM DD, YYYY (dddd)', // Sunday, 09/20/2020
  lastDay: '[Yesterday] (dddd)', // Yesterday (Sunday)
  lastWeek: '[Last] dddd, MMM DD, YYYY', // Last Monday, 09/14/2020
  sameElse: 'MMM DD, YYYY (dddd)' // 09/14/2020 (Friday)
};