import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import SeparatorLine from './SeparatorLine';
import { LockClosedIcon,LockOpenIcon,ClockIcon} from '@heroicons/react/20/solid';

import EventCalendarIcon from './EventCalendarIcon';
import { faPenToSquare,faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SingleEventFeedCard({eventObject}) {
    
    const [hideCommentBox, setHideCommentBox] = useState(true);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [pastEvent, setPastEvent] = useState(false);

    const [students, setStudents] = useState([]);
    const [gradeLevels, setGradeLevels] = useState([]);

    const getGradeLevels = ()=>{
        let students = eventObject.invitations.map((invitation)=>{
            return invitation.student
            }
        )
        let gradeLevels = students.map((student)=>{
            return student.gradeLevel
        })
        gradeLevels = gradeLevels.sort((a,b)=>a-b);
        gradeLevels = [...new Set(gradeLevels)];
        setGradeLevels(gradeLevels);
    }

  useEffect(()=>{
    if(moment(eventObject.eventDateTime).isBefore(moment())){
        setPastEvent(true)
    }
  })

  useEffect(()=>{
    getGradeLevels()
  },[eventObject])

  const momentOptions = {
    sameDay: '[Today] (dddd) [at] LT', // Today (Monday) at 2:30 PM
    nextDay: '[Tomorrow] (dddd) [at] LT', // Tomorrow (Tuesday) at 2:30 PM
    nextWeek: 'dddd [at] LT', // Sunday at 2:30 PM
    lastDay: '[Yesterday] (dddd) [at] LT', // Yesterday (Sunday) at 2:30 PM
    lastWeek: '[Last] dddd [at] LT', // Last Monday at 2:30 PM
    sameElse: 'dddd [at] LT' // Friday at 2:30 PM
};

  return (
    <>
    <div id='event-card' className='text-sm bg-white shadow my-2 rounded-b-2xl'>
        <Link to={`/school/events/${eventObject.id}`} >
          <div id='event-title'     
            className='transition delay-50 flex items-center space-x-5 justify-between text-indigo-500 hover:bg-indigo-500 hover:shadow-sm hover:text-white px-3 py-1 mb-2 border-l-2 border-b-2 border-indigo-500 group'>
            {/* <FontAwesomeIcon icon={faCalendarCheck} className='h-6 w-6 ' /> */}
            <div className='flex w-full'>
                <p className='text-base font-semibold hover:cursor-pointer text-xl '>{eventObject.eventName}</p>
            </div>
            <p className='min-w-fit px-3 py-1 font-semibold rounded-2xl border-2 border-slate-200 text-xs group-hover:text-white'>{eventObject.eventCategory?.categoryName.toUpperCase()}</p>
          </div>
        </Link>
        <div className='px-3 py-1'>
            <div className='flex w-full justify-between rounded space-x-4'>
                <div className='rounded border flex flex-col h-full'>
                    <div className='flex px-5 py-2 justify-center'>
                        <ClockIcon className='h-6 w-6 text-blue-500 mr-2'/>
                        <p className='text-sm text-center font-semibold text-blue-700 '>{moment(eventObject.eventDateTime).calendar(null,momentOptions)} </p>
                    </div>
                {
                    moment().isBefore(moment(eventObject.signUpDeadline))?
                    eventObject.openToAll?
                    ( 
                        <div className="px-5 py-1 text-sm bg-green-500 text-center rounded-b  flex justify-center">
                            <LockOpenIcon className='h-5 w-5 text-white mx-2' /> 
                            <p className='font-semibold text-white'>Open to Join</p>
                        </div>
                    )
                    :(
                        <div className="px-5 py-1 text-base bg-gray-100 text-center rounded-b flex justify-center">
                            <LockClosedIcon className='h-6 w-6 text-red-500 mx-2' /> 
                            <p className='font-semibold'>Invite Only</p>
                        </div>
                        )
                    :(
                        <div className="px-5 py-1 text-base bg-amber-500 text-center rounded-b ">
                        <p className='font-semibold text-white'>Sign up Closed</p>
                        </div>
                    )
                }
                </div>
                <EventCalendarIcon date={eventObject.eventDateTime} />
            </div>
  {/* ----------------- separator ----------------- */}
            <div className='px-1 py-1 flex justify-between items-center ' >
                <div className='flex flex-wrap space-x-2 items-center'>
                    {
                        gradeLevels.map((grade,index)=>{
                            return <p key={index} className='font-semibold text-base text-center flex items-center justify-center w-8 h-8 rounded-full shadow-sm border-2 border-teal-500'>{grade}</p>
                        })
                    }
                </div>
                <div className='py-0'>
                    <p className='font-semibold text-xs '>Created by:
                        <span className='text-indigo-500'> {eventObject?.staff.firstName} {eventObject?.staff.lastName} </span>
                     {eventObject.staff.profilePicture}
                     </p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
