import React from 'react'

export default function Footer() {
  return (
    <footer className="bg-gray-900 pt-12" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 py-6 sm:py-18 lg:px-4 lg:py-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <img
            className="h-20"
            src="./logos/appLogo.png"
            alt="Company name"
          />
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">About US</h3>
                <ul role="list" className="mt-6 space-y-4">
                    <li className="text-sm leading-6 text-gray-300 hover:text-white">Technology empowers education by making learning accessible, personalized, and limitless.</li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Contact Us</h3>
                <ul role="list" className="mt-6 space-y-4 text-white">
                    <li className="text-sm leading-6 text-gray-300 hover:text-white">Our Address:</li>
                    <li className="text-sm leading-6 text-gray-300 hover:text-white"></li>
                    <li className="text-sm leading-6 text-gray-300 hover:text-white"> Knoxville </li>
                    <li className="text-sm leading-6 text-gray-300 hover:text-white"> TN, United States</li>
                        
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Links</h3>
                <ul role="list" className="mt-6 space-y-4 text-white">
                    <li > 
                        <a href='#' className="text-sm leading-6 text-gray-300 hover:text-white">Home</a>
                    </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Our Partners</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a className="text-sm leading-6 text-gray-300 hover:text-white"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
    </footer>
  )
}
