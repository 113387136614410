import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor'
import { AuthenticationContext } from '../../../services/AuthContextModule'
import {Link, useParams } from "react-router-dom";
import moment from 'moment';
import SkeletonTableRow from '../../pageComponents/SkeletonTableRow';

export default function StudentUccEvents() {

  const {authState} = useContext(AuthenticationContext)
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [isLoading, setIsLoading] = useState(true);

  const useAxiosWithInterceptor = axiosWithInterceptor();

  const [thisWeekVisits, setThisWeekVisits] = useState([]);
  const [thisMonthVisits, setThisMonthVisits] = useState([]);
  const [futureVisits, setFutureVisits] = useState([]);
  const [pastVisits,setPastVisits] = useState([]);

  const [visitTable, setVisitTable] = useState([]);

  const [activeTabs, setActiveTabs] = useState([
      {active:true, tabName:"IN A WEEK", content: thisWeekVisits},
      {active:false, tabName:"30 DAYS",content:thisMonthVisits},
      {active:false, tabName:"30 DAYS +", content: futureVisits},
      {active:false, tabName:"PAST", content: pastVisits}
  ]);

  const handleSetTabContent = (index, tabContent) =>{
        const newActiveTabs = [...activeTabs];
        newActiveTabs[index].content = tabContent;
        setActiveTabs(newActiveTabs);
  }

  const handleVisitTable = (index,visitData)=>{
        const newActiveTabs = [...activeTabs];
        newActiveTabs.forEach((tab)=>{
          tab.active = false;
        });
        newActiveTabs[index].active = true;
        setActiveTabs(newActiveTabs);
        setVisitTable(visitData);
  }

  const handleThisWeek = (eventData)=>{
      // filter visitData only for within 7 days 
      const thisWeek = eventData.filter((event) => {
        const eventDate = moment(event?.eventDateTime);
        const now = moment();
        return eventDate.isBetween(now, now.add(7, 'days'), null, '[]');
      });
    
      setThisWeekVisits(thisWeek);
      handleSetTabContent(0, thisWeek);
  }

  const handleThisMonth = (eventData) => {
    // filter visitData only for within the next 30 days
    const now = moment();
    const in30Days = moment().add(30, 'days');
    
    const thisMonth = eventData.filter((event) => {
      const eventDate = moment(event?.eventDateTime);
      return eventDate.isBetween(now, in30Days, null, '[]') && eventDate.isAfter(now);
    });
  
    setThisMonthVisits(thisMonth);
    handleSetTabContent(1, thisMonth);
  };

  const handleFuture = (eventData) => {
    // filter visitData only for more than 30 days
    const future = eventData.filter((event) => {
      const eventDate = moment(event?.eventDateTime);
      const now = moment();
      return eventDate.isAfter(now.add(30, 'days'));
    });
    setFutureVisits(future);
    handleSetTabContent(2, future);
  };

  const handlePast = (eventData) => {
    // Filter visitData only for past events
    const pastVisits = eventData.filter((event) => {
      const eventDate = moment(event?.eventDateTime);
      const now = moment();
      return eventDate.isBefore(now);
    });
  
    setPastVisits(pastVisits);
    handleSetTabContent(3, pastVisits);
  };

  const getAllVisits = async ()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/ucc-events`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          handleThisWeek(response.data.payload);
          handleThisMonth(response.data.payload);
          handleFuture(response.data.payload);
          handlePast(response.data.payload);

          const thisWeekInvites = response.data.payload.filter((event) => {
            const eventDate = moment(event?.eventDateTime);
            const now = moment();
            return eventDate.isBetween(now, now.clone().add(7, 'days'), null, '[]');
          });
          
          handleSetTabContent(0,thisWeekInvites);
          setVisitTable(thisWeekInvites);
        }
      })
      .catch((error)=>{
        console.log(error)
      })
      .finally(()=>{
        setIsLoading(false);
      });
  }

  const displayStatus = (event)=>{
    let studentInvitation = event.invitations.find((invitation)=>{
      return invitation.student.id === userId;
    })
    return studentInvitation?.status?.toUpperCase();
  }

  useEffect(()=>{
    document.title = "Schoolley | UCC Events";
      getAllVisits();
  },[])

  return (
    <>
  <Layout>
    <div className="md:w-full w-full">
      <div className='bg-opacity-75 px-1 py-2 lg:mx-10'>
        <p className='text-xl font-bold mb-2'>UCC Events</p>
        <div className='overflow-x-auto shadow-sm rounded-t-2xl'>
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <div className='mx-auto grid grid-cols-4 xs:grid-cols-2 lg:grid-cols-4'>
              {
                activeTabs.map((tab,index)=>{
                  return (
                    <button key={index} 
                      className={`transition delay-100 shadow-sm flex flex-col items-baseline flex-wrap justify-between gap-x-4 border-t border-gray-900/5 px-2 py-1 sm:px-6 lg:border-t-0 xl:px-8 hover:bg-cyan-200 ${tab.active?"bg-cyan-300 ":""}`}
                      onClick={()=>{handleVisitTable(index,tab.content)}}>
                    <p className="w-full text-sm font-semibold text-gray-500">{tab.tabName}</p>
                    <p className="w-full flex-none text-2xl font-medium leading-10 tracking-tight">{activeTabs[index].content.length}</p>
                  </button>
                  )})
              }
            </div>
          </div>
        </div>

        <div className="bg-cyan-500 mt-2 rounded-b-2xl">
          <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-0">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead className="text-white">
                <tr className='text-center'>
                  <th scope="col" className="px-3 py-2 text-sm font-semibold w-[250px]">
                    Event
                  </th>
                  <th scope="col" className="hidden px-2 py-2 text-sm font-semibold lg:table-cell w-[100px]">
                  Universities </th>
                  <th scope="col" className="px-3 py-2 text-sm font-semibold w-[300px]">
                    Date/Time
                  </th>
                  <th scope="col" className="hidden px-3 py-2 text-sm font-semibold w-[150px] lg:table-cell">
                    Location
                  </th>
                  <th scope="col" className="hidden lg:table-cell px-3 py-2 text-sm font-semibold w-[120px]">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {
                  isLoading?
                  <SkeletonTableRow colNumber={5} rowNumber={6} />
                  :
                  visitTable.map((event)=>{
                  return( 
                    <tr key={event?.id} className='transition delay-50 text-center hover:bg-cyan-100'>
                      <td className="text-start py-1 px-3 text-sm font-semibold text-blue-500 w-[250px] sm:pl-0">
                        <div>
                          <Link 
                            className="block truncate whitespace-normal break-words "
                            to={`/student/ucc-events/${event.id}`}>
                            <p className='font-bold text-blue-500 text-sm'>{event.eventName}</p>
                          </Link>
                        </div>
                        <div>
                          <dl className="lg:hidden">
                            <p className='text-xs'>{event.location}</p>
                          </dl>
                        </div>
                      </td>
                      <td className='hidden lg:table-cell whitespace-nowrap px-3 font-semibold text-sm w-[100px]'>
                        {event.visiting_universities?.length}
                      </td>
                      <td className="whitespace-nowrap text-sm flex justify-center w-[320px]">
                        <div className='flex justify-end'>
                          <div className='rounded-l-2xl bg-cyan-600 text-white font-semibold px-2 py-1 w-[160px]'>
                          {moment(event.eventDateTime).calendar("null",momentOptions2)} 
                          </div>
                          <div className='rounded-r-2xl bg-red-500 text-white px-3 py-1 font-semibold w-[120px]'>
                          {moment(event.eventDateTime).format("LT")}
                          </div>
                        </div>
                      </td>

                      <td className="hidden lg:table-cell whitespace-nowrap px-3 text-sm w-[120px]">{event.location}
                      </td>
                        
                      <td className={`hidden lg:table-cell whitespace-nowrap px-3 text-xs font-semibold ${event.invitations[0]?.status ==="accepted"?"text-green-500":event.invitations[0]?.status ==="declined"?"text-red-500":"text-blue-500"} w-[150px]`}>
                      {
                        displayStatus(event)
                        }
                      </td>
                    </tr>
                    )})
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  </>
  )
}


const momentOptions2 = {
  sameDay: '[Today] (ddd)', // Today (Monday)
  nextDay: '[Tomorrow] (ddd)', // Tomorrow (Tuesday)
  nextWeek: 'MMM DD, YYYY (ddd)', // Sunday, 09/20/2020
  lastDay: '[Yesterday] (ddd)', // Yesterday (Sunday)
  lastWeek: '[Last] ddd, MMM DD, YYYY', // Last Monday, 09/14/2020
  sameElse: 'MMM DD, YYYY (ddd)' // 09/14/2020 (Friday)
};